import { template as template_d4997f696b0b43269ae5f048e51e06e6 } from "@ember/template-compiler";
const FKText = template_d4997f696b0b43269ae5f048e51e06e6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
